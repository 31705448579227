/**
 * @author linzl
 * @see wiki接口 http://wiki.lkcoffee.com/pages/viewpage.action?pageId=15514274
 */
import utils from '@spa/utils';
import luckyTrack, { MONITOR_TRACK } from '@spa/utils/lucky-track';

// 微信小程序tab页路径
const WX_MINI_TABS = [
  '/pages/index/menu',
  '/pages/index/home',
  '/pages/index/order/list',
  '/pages/index/memberCard/memberCard',
  '/pages/index/member/center',
];

// 支付宝小程序tab页路径
const ALIPAY_MINI_TABS = [
  '/pages/home/home',
  '/pages/product/list/list',
  '/pages/product/cart/cart',
  '/pages/order/list/list',
  '/pages/member/index/index',
];

class MiniApi {
  constructor() {
    this.transferUrl = `/pages/index/pageTransfer`;
  }

  // 获取当前小程序类型
  currentMiniProgram() {
    // window.my 为支付宝小程序
    // 手动埋点开始
    const miniApp = window.my || window.wx?.miniProgram;
    const trackCode = window.my ? 'MY' : 'WX';
    luckyTrack(MONITOR_TRACK[`SDK_${trackCode}_START`]);
    if (miniApp) {
      // 手动埋点成功
      luckyTrack(MONITOR_TRACK[`SDK_${trackCode}_SUCCESS`]);
      return miniApp;
    }
    // 手动埋点失败
    luckyTrack(MONITOR_TRACK[`SDK_${trackCode}_FAIL`]);
    throw new Error('请引入微信或者支付宝脚本');
  }

  // 从当前页面，跳转到应用内的某个指定页面
  navigateTo(url) {
    if (!this.miniProgram) {
      this.miniProgram = this.currentMiniProgram();
    }
    this.miniProgram?.navigateTo?.({ url });
  }

  // 关闭当前页面，跳转到应用内的某个指定页面
  redirectTo(url) {
    if (!this.miniProgram) {
      this.miniProgram = this.currentMiniProgram();
    }
    this.miniProgram?.redirectTo?.({ url });
  }

  // 跳转到指定标签页（tabbar）页面，并关闭其他所有非标签页页面
  switchTab(url) {
    if (!this.miniProgram) {
      this.miniProgram = this.currentMiniProgram();
    }
    this.miniProgram?.switchTab?.({ url });
  }

  // postmessage给小程序传递数据
  postMessage(data = {}) {
    if (!this.miniProgram) {
      this.miniProgram = this.currentMiniProgram();
    }
    this.miniProgram?.postMessage?.(data);
  }

  /**
   * 关闭当前webview
   */
  closeWebView(delta = 1) {
    if (!this.miniProgram) {
      this.miniProgram = this.currentMiniProgram();
    }
    this.miniProgram?.navigateBack?.({ delta });
  }

  /**
   * 打开登录并跳转回来
   */
  openLogin() {
    // 只针对微信小程序
    // 小程序webview链接前缀
    const MINI_WEBVIEW_URL_PREFIX = '/pages/webview/common?needMobile=1&redirect_h5=';
    // 当前h5页面链接
    const CURRENT_H5_URL = location.href;
    // 小程序重登录链接前缀
    // direction: navigateTo 和 redirectTo
    const MINI_RELOGIN_URL_PREFIX = '/pages/subMain/wxlogin/wxlogin?nativeLogin=1&direction=redirectTo&redirectUrl=';
    // 重新登录链接
    const reLoginUrl = `${MINI_RELOGIN_URL_PREFIX}${encodeURIComponent(MINI_WEBVIEW_URL_PREFIX + encodeURIComponent(CURRENT_H5_URL))}`;
    // 执行重登录
    this.redirectTo(reLoginUrl);
  }

  /**
   * 打开相应地址
   * @param {routeKey, params} options
   */
  navToPage(options) {
    if (options.routeKey) {
      const urlParams = options.params && utils.objToUrl(options.params);
      let openMethod = 'navigateTo';
      if (options.openMethod) {
        openMethod = options.openMethod;
      }
      this[openMethod]?.(`${this.transferUrl}?entry=${options.routeKey}&${urlParams}`);
    } else {
      console.error('mini program transfer url is need router key');
    }
  }

  // 通用跳转小程序路径方法
  openMiniPage(url, type) {
    if (!url) {
      return;
    }
    const path = url.split('?')[0];
    // 判断路径是否为tab页路由
    if ((window.my && ALIPAY_MINI_TABS.includes(path)) || (window.wx && WX_MINI_TABS.includes(path))) {
      this.switchTab(url);
    } else {
      if (type && typeof this?.[type] === 'function') {
        this[type](url);
        return;
      } else {
        this.navigateTo(url);
      }
    }
  }
}

// 小程序路由key
export const miniRouteKey = {
  // 首页
  HOME: 'home',
  // 菜单页
  MENU: 'menu',
  // 订单列表
  ORDER: 'order',
  // 订单详情
  ORDERDETAIL: 'orderDetail',
  //  咖啡券充值页
  COUPON: 'coupon',
  //  幸运送
  LUCKYSEND: 'luckySend',
  //  礼品卡
  GIFTCARD: 'giftCard',
  //  h5支付
  H5PAY: 'h5pay',
  //  钱包
  WALLET: 'wallet',
  //  购物车
  CART: 'cart',
  // 新人专区购买商品
  PRODUCTDETAIL: 'productDetail',
  // 新人专区切换地址
  ADDRESSSELECT: 'addressSelect',
  // 保存图片
  SAVEIMG: 'saveImage',
  // 优惠券列表
  COUPONLIST: 'couponList',
  // 我的
  CENTER: 'center',
  // 通用
  WEBVIEW: 'webview',
  // 领券中心发商家券
  COUPONSUCCESS: 'couponSuccess',
  // 支付中心
  PAYCENTER: 'paycenter',
  // 社群裂变
  INVITEREWARD: 'inviteReward',
  // 发票管理
  INVOICEMANAGE: 'invoicemanage',
  // h5支付
  H5PAY: 'h5pay',
  // 自由卡落地页
  // 参数：
  // entranceType=24 ： 入口类型
  // isFilter=1：是否过滤当前卡
  // masterCardCode：主卡编号
  // equityCardCode：子卡编号
  // isLandingPage=1：是否是落地页
  MEMBERCARD: 'memberCard',
  // 社群专享价中的套餐商品
  PRODUCTPLAN: 'productPlan',
  // 商品详情
  PRODUCT_DETAIL: 'productDetail',
};

export default new MiniApi();
