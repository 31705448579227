import Home from './home';
const ConfirmOrder = () =>
  import(
    /* webpackChunkName: 'coffee/wechat-community/coupon-centre/order' */ './order/index'
  );
const ConfirmOrderResult = () =>
  import(
    /* webpackChunkName: 'coffee/wechat-community/coupon-centre/order/result' */ './order/result'
  );

const TaskMedal = () =>
  import(
    /* webpackChunkName: 'coffee/wechat-community/coupon-centre/task-medal' */ './task-medal/TaskMedal'
  );

export default {
  Home,
  ConfirmOrder,
  ConfirmOrderResult,
  TaskMedal,
};
