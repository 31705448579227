/**
 * @author linzl
 */
import App from '@coffee/pages/app';

export default [
  {
    title: '万事达支付-添加',
    path: '/mastercard/add',
    component: App.Mastercard.AddInfo,
  },
  {
    title: '万事达卡支付-支付',
    path: '/mastercard/pay',
    component: App.Mastercard.WaitInfo,
  },
  {
    title: '引导关注公众号',
    path: '/app/focus',
    component: App.FocusWechat,
  },
  {
    title: '直播',
    path: '/app/live',
    component: App.LiveVideo,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '拉新',
    path: '/app/invite/newuser',
    component: App.InviteNewUser,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: 'cms拉新',
    path: '/appinvitecms/:id',
    component: App.InviteNewUser,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '开学季拉新2022',
    path: '/invite/schoolseason2022',
    component: App.InviteOfSchool,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '开学季拉新',
    path: '/app/invite/schoolseason',
    component: App.InviteSchoolSeason,
  },
  {
    title: '企业资质公示',
    path: ['/appClient/helpCenter/storeAptitude', '/app/qualifyNotification'],
    component: App.QualifyNotification,
  },
  {
    title: 'app企业账户',
    path: '/app/enterpriseApply',
    component: App.EnterpriseAccount,
    meta: {
      pageTitle: '企业账户',
    },
  },
  {
    title: '二级页面',
    path: '/appClient/helpCenter/subContent',
    component: App.Agreement.CommonAgreement,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: 'app中 协议与说明列表',
    path: ['/app/agreement/list', '/appClient/agreement/list'],
    component: App.Agreement.List,
  },
  {
    title: 'app支付协议',
    path: '/appClient/helpCenter/paymentProtocol',
    component: App.Agreement.Payment,
  },
  {
    title: 'app企业用户协议',
    path: '/appClient/helpCenter/memberAgreement',
    component: App.Agreement.CommonAgreement,
    meta: {
      pageTitle: '企业用户协议',
    },
  },
  {
    title: 'app企业营销协议',
    path: '/appClient/helpCenter/marketingAgreement',
    component: App.Agreement.CommonAgreement,
    meta: {
      pageTitle: '企业营销协议',
    },
  },
  {
    title: 'app企业服务规则',
    path: '/app/corpAgreement',
    component: App.Agreement.Enterprise,
  },
  {
    title: 'app隐私权政策',
    path: '/appClient/helpCenter/privacyAgreement',
    component: App.Agreement.CommonAgreement,
  },
  {
    title: '付费会员服务协议',
    path: ['/appClient/helpCenter/vipPayAgreement', '/app/lkcard/service'],
    component: App.Agreement.VipPay,
    meta: {
      pageTitle: '瑞幸咖啡付费会员服务协议',
    },
  },
  {
    title: '自由卡自动续费说明',
    path: ['/appClient/helpCenter/autoRenew', '/app/lkcard/autoRenew'],
    component: App.Agreement.LkcardAutoRenew,
    meta: {
      pageTitle: '瑞幸咖啡自动续费开通及取消说明',
    },
  },
  {
    title: '瑞幸咖啡单用途商业预付卡章程',
    path: '/appClient/helpCenter/virtualProductAgreement',
    component: App.Agreement.VirtualProduct,
    meta: {
      pageTitle: '瑞幸咖啡单用途商业预付卡章程',
    },
  },
  {
    title: '',
    path: '/appClient/helpCenter/thirdParty',
    component: App.Agreement.ThirdParty,
    exact: false,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '索迪斯支付账户绑定协议',
    path: '/appClient/helpCenter/sodexoAgreement',
    component: App.Agreement.Sodexo,
  },
  {
    title: '注销说明',
    path: '/appClient/helpCenter/unregisterInstructions',
    component: App.Agreement.UnregisterInstructions,
  },
  {
    title: 'SDK名单',
    path: '/appClient/helpCenter/sdkList',
    component: App.Agreement.SdkList,
  },
  {
    title: '发票说明',
    path: '/appClient/helpCenter/faq/invoiceDesc',
    component: App.HelpCentre.Invoice.Description,
  },
  {
    title: '招商加盟',
    path: ['/app/joinUs/list', '/appClient/joinUs/list'],
    component: App.JoinUs.List,
  },
  {
    title: '咖啡app下载',
    path: ['/app/download', '/go'],
    component: App.Download.Coffee.NormalDownload,
  },
  {
    title: '咖啡HK-app下载',
    path: '/app/download4hk',
    component: App.Download.Coffee.HkCoffeeDownload,
  },
  {
    title: '咖啡app下载且含小程序',
    path: '/app/newDownload',
    component: App.Download.Coffee.MiniDownload,
  },
  {
    title: 'oa-app下载',
    path: '/oa/download',
    component: App.Download.OfficeAutomation,
  },
  {
    title: '配送app下载',
    path: '/go/download',
    component: App.Download.Dispatche,
  },
  {
    title: '门店app下载',
    path: '/store/download',
    component: App.Download.Store,
  },
  {
    title: '员工端app下载',
    path: '/employee/download',
    component: App.Download.Employee,
  },
  {
    title: '门店帮助中心',
    path: '/store/helpCenter',
    component: App.HelpCentre.Store,
  },
  {
    title: '配送端帮助中心',
    path: '/appDispatch/helpCenter*',
    component: App.HelpCentre.Dispatche,
  },
  {
    title: '限时拉新',
    path: '/app/invitePeriodLimit',
    component: App.InvitePeriodLimit,
  },
  {
    title: '邀好友体验瑞即购',
    path: '/app/invite/unmannedexpress',
    component: App.InviteUnmannedExpress,
  },
  {
    title: '订单交易履约',
    path: '/app/order-chain',
    component: App.BlockChain.OrderChain,
  },
  {
    title: '上链信息',
    path: '/app/on-chain',
    component: App.BlockChain.OnChain,
  },
];
