import Coffee from './coffee';
import Dispatche from './dispatche';
import Employee from './employee';
import OfficeAutomation from './office-automation';
import Store from './store';

export default {
  Coffee,
  Dispatche,
  Employee,
  OfficeAutomation,
  Store,
};
