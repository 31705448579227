import CouponCentre from './coupon-centre';
import DiceCoupon from './dice-coupon';
import SlotCoupon from './slot-coupon';
import WawaCoupon from './wawa-coupon';
import SubscribeLive from './subscribe-live';
import ExclusivePrice from './exclusive-price';
import GuideWelfare from './guide-welfare';

export default {
  CouponCentre,
  DiceCoupon,
  SlotCoupon,
  WawaCoupon,
  SubscribeLive,
  ExclusivePrice,
  GuideWelfare
}