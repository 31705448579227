// 配置数据
const splitConfig = {
  // 路由
  activityName: 'dice',
  // 背景色
  bgImgUrl:
    'https://s1.luckincoffeecdn.com/luckywaprm/media/latte-ruinaice.d445cafe.jpg',
  // logo 类型：dark(深色logo)/white(白色logo)/transparent(不展示)
  logoType: 'dark',
  // 额外按钮
  recExtraUrl: 'http://47.107.86.55:3000/',
  // 额外标题
  recExtraTitle: '我的git服务地址',
  // 额外的按钮样式
  extraBtnStyle: {
    backgroundColor: 'red',
    color: '#fff',
  },
  // 修改
  modifyMobileStyle: {
    color: '#222773',
    borderBottomColor: '#222773',
  },
  // 手机号码描述
  recPhoneTipStyle: {
    color: '#4b4c4d',
  },
  // 手机号
  recPhoneStyle: {
    color: '#4b4c4d',
  },
  // 领取内容样式
  recContentStyle: {
    top: '8.7rem',
  },
  // 手机号码输入框
  mobileInputStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    border: '1px solid #fff',
  },
  // 领取成功提示语
  recMsgStyle: {
    color: '#022773',
  },
  // 领券的券名
  recCouponNameStyle: {
    color: '#2a7de2',
  },
  // 领券按钮名称
  recBtnName: '试试手气111',
  // 领券按钮样式
  recBtnStyle: {
    color: '#fff',
    backgroundColor: '#022773',
  },
  // 警示提示语
  warnMsgStyle: {
    marginTop: '1rem',
  },
  // 二维码描述
  qrdescStyle: {
    color: '#022773',
  },
  // 二维码描述名称
  qrDescName: '关注公众号',
  // 分享按钮名称
  shareBtnName: '分享幸运给小伙伴们',
  // 分享按钮样式
  shareBtnStyle: {
    color: '#fff',
    backgroundColor: 'red',
  },
};

// 领取数据
const detail = {
  gainerPhone: '16666666666',
  getStatus: 0,
  messages: '少时诵诗书所所所所所所所多多错所',
  receivePlanName: '8.8折券',
  recordList: [
    {
      activityName: '13元瑞纳冰立减券',
      gainedTime: '07/20 10:37',
      gainerImgUrl:
        'https://img03.luckincoffeecdn.com/group1/M01/6B/93/CtQLPF1GhoeAfwC5AAAMCQvI7Sc2220.wx',
      gainerName: 'Eugene',
    },
    {
      activityName: '全场饮品满28减15元券',
      gainedTime: '07/20 10:37',
      gainerImgUrl:
        'https://img03.luckincoffeecdn.com/group1/M00/3B/B0/CtQLPFwAiTSAOvYUAAADHpk5GPE1561.wx',
      gainerName: 'Sean*Ye',
    },
    {
      activityName: '15元拿铁立减券',
      gainedTime: '07/20 10:35',
      gainerImgUrl:
        'https://img03.luckincoffeecdn.com/group1/M00/FD/8E/CtQyFV7DI7KAcdncAAAJRwSZHrs1548.wx',
      gainerName: '陈天玲',
    },
  ],
  shareContent: {
    programShareIcon: '',
    shareChannel: 0,
    shareDigest: '领券尝新',
    shareIcon:
      'https://img03.luckincoffeecdn.com/group1/M00/F7/93/CtQyFV74oqyAKpsRAAB029wu66w799.jpg',
    shareLink:
      'https://m.luckincoffee.com/latte-ruinaice/autumn?orderNo=MbFeEk9084zmygp7ZfG2LG0ACcQQlYL616VCVOQ_0yg%3D&activityNo=9VfnQnvUIqPzK8VJ-Xm8QA%3D%3D&inviteCode=YBqLOc-5yw3kEPofTdM2yg%3D%3D',
    shareTitle: '「陨石拿铁」冰爽升级！领20张咖啡券~围观瑞纳冰季系列新品！',
  },
  showPoints: 0,
  title: '「陨石拿铁」冰爽升级！领20张咖啡券~围观瑞纳冰季系列新品！',
};

const friendTrend = {
  msg: '陈天天正在喝这些咖啡',
  productList: [
    {
      productImg:
        'https://img04.luckincoffeecdn.com/group1/M00/3A/D0/CtQLO1zO8WWAFQCAAALD1SjDfQo377.jpg',
      productName: '加浓美式',
    },
    {
      productImg:
        'https://img.luckincoffeecdn.com/group1/M00/00/03/CtQLPFnkUeWAQp7-AAL6ayNm9Jk793.png',
      productName: '摩卡',
    },
  ],
  wechatPicUrl:
    'https://img04.luckincoffeecdn.com/group1/M00/E3/41/CtQLO1w2rV6AOKUCAAF-JynYqnM808.jpg',
};
// 好友领劵
// const friendMsgList = [
//   '咖啡的世界很小，唯有精致与深邃',
//   '这突如其来的好运是怎么回事？',
//   '哇！国际大赛金奖咖啡，现在就去喝～',
//   '我就喜欢大家都领到券的样子',
//   '开心！美好一天从luckin开始~',
//   '元气满满的一天，精力充沛100分！',
//   '哈！今天的手气很稳',
//   '我正在喝国际品鉴大赛金奖咖啡',
//   '咖啡与阳光，我全都要~',
//   '给我一杯luckin，幸运一整天~',
//   '世界上我最喜欢的味道，是咖啡的香气',
// ];

// const ruleList = [
//   '下单即可送优惠券给小伙伴，自己也能抢~如订单取消，链接也会失效哦',
//   '每个链接仅限20个账号领取，每个账号每天限领3张',
//   '每个链接按照领取顺序随机发放优惠券，优惠券的有效期及适用范围等详见优惠券使用规则',
//   '优惠券仅限本人使用，不得以任何方式滥用优惠券或用于商业用途。上述情形一经发现，则冻结账号处理',
//   '如果您的好友为（luckin coffee及小鹿茶）新用户，参与活动即可获赠新人专享大券包。【特别提示：如您邀请的好友已经在luckin coffee或小鹿茶注册，则不属于新用户】',
//   '新用户只要产生消费（含新人券包中任意一张饮品券消费），您即可获得相应的饮品抵用金，可抵扣相应饮品面价，1张饮品抵用金仅限用于1件商品（不可抵扣配送费，不可抵扣风味糖浆、奶盖等附加口味，不可叠加使用），本券不与其他活动同享，有效期30天。',
//   '新用户首次登陆luckin coffee APP、小鹿茶luckinteaAPP及小程序，可领取新人专享大券包，同一手机设备、同一手机号码仅可领取一次新人专享大券包。新人专享大券包，总价值100元，内含6张饮品抵用金：1张20元7天有效饮品抵用金＋1张15元7天有效饮品抵用金+3张15元15天有效饮品抵用金+1张待解锁的20元饮品抵用金。',
//   '您可登录luckin coffee APP、小鹿茶luckintea APP及小程序，查看身边是否有开业门店',
//   '优惠券、新人专属大券包均不可转让、不开发票、不找零、不兑换现金。',
//   '优惠券的使用规则以luckin coffee APP、小鹿茶luckintea APP及小程序公示规则为准。',
// ];

const receiveResult = {
  receivePlanName: '14元瑞纳冰立减券',
  receivePlanNo: 'CP202005160019',
  wechatCouponSendDTO: null,
  wechatCouponUrl:
    'https://action.weixin.qq.com/busifavor/getcouponinfo?coupon_code=SY107060771996975104&open_id=ogY160StsUTFInb4Xlb4tqB7D_ak&sign=7E56BA63AF662C68D43DFC3CE315C899D5AEA24255780E2D822998DC94E957EC&stock_id=1216010000000249&out_request_no=10338034988878422016&send_coupon_merchant=1526241811',
  cashAwardStr: '',
  receiveCouponOpenId: 'ogY160StsUTFInb4Xlb4tqB7D_ak',
  receivePlanPicTag: 5,
  userId: 10137919,
  phoneNo: '188****4207',
};

export default {
  splitConfig,
  detail,
  // friendMsgList,
  // ruleList,
  friendTrend,
  receiveResult,
};
