import config from '@spa/config';
import utils from '@spa/utils';
import appApi from '@spa/utils/app-api';
import AJAX from '@spa/utils/http';

/**
 * @description 登录权限管理
 */
class LoginAuth {
  constructor() {
    this.urlParams = utils.getUrlParams() || {};
    // 是否有查看权限(默认无权限)
    this.hasViewAuth = false;
    // 是否登录(默认未登录)
    this.isLogin = false;
  }
  // 配置key
  static CONFIG_KEY = {
    MOBILE: 'user_mobile',
    MEMBER: 'member_id',
    MEMBER_NO: 'member_no',
  };
  // 缓存key
  static CACHE_KEY = {
    MOBILE: `lk_user_mobile_${config['client_env']}`,
    MEMBER: `lk_member_id_${config['client_env']}`,
    MEMBER_NO: `lk_member_no_${config['client_env']}`,
  };
  async init() {
    // hadAuthed: 是否已经授权过
    // isForceInnerApp: 是否强制登录(是否强制要求在站内)
    const { hadAuthed = false, isForceInnerApp = false } = config;
    // 需要强制登录
    if (isForceInnerApp) {
      // 1、APP中
      if (config['client_env'] === 'app') {
        if (hadAuthed) {
          // 已授权
          this.hasViewAuth = true;
          this.isLogin = true;
          return;
        } else {
          // 未授权
          const isLoginSuccess = await this.handleInApp();
          if (isLoginSuccess) {
            this.hasViewAuth = true;
            this.isLogin = true;
          } else {
            this.hasViewAuth = false;
            this.isLogin = false;
          }
          return;
        }
      }
      // 2、小程序中
      else if (
        // @ts-ignore
        ['mini', 'alipaymini'].includes(config['client_env'])
      ) {
        // 由于小程序退出登录未能清cookie，导致下一个登录人，会话未重置
        // 即每次都进行登录授权
        const isLoginSuccess = await this.handleInMini();
        if (isLoginSuccess) {
          // 授权码校验-成功
          this.hasViewAuth = true;
          this.isLogin = true;
        } else {
          // 授权码校验-失败
          if (hadAuthed) {
            // 有上一次校验成功记录
            this.hasViewAuth = true;
            this.isLogin = true;
          } else {
            // 无上一次校验记录
            this.hasViewAuth = false;
            this.isLogin = false;
          }
        }
      }
    } else {
      // 无强制登录  ---> 有权限查看
      this.hasViewAuth = true;
      this.isLogin = hadAuthed;
      // 无强制登录，但是在站内，若有登录就执行下登录
      if (!hadAuthed) {
        await this.handleInApp();
      }
      await this.handleInMini();
    }
    this.setUserCache();
  }
  // 处理用户缓存
  setUserCache() {
    // 无缓存信息 且 有登录
    if (!config[LoginAuth.CONFIG_KEY.MOBILE] && this.isLogin) {
      const userMobile = localStorage?.getItem(LoginAuth.CACHE_KEY.MOBILE);
      // const memberId = localStorage?.getItem(LoginAuth.CACHE_KEY.MEMBER);
      const memberNo = localStorage?.getItem(LoginAuth.CACHE_KEY.MEMBER_NO);
      config[LoginAuth.CONFIG_KEY.MOBILE] = userMobile || '';
      // config[LoginAuth.CONFIG_KEY.MEMBER] = memberId || '';
      config[LoginAuth.CONFIG_KEY.MEMBER_NO] = memberNo || '';
    }
  }
  // 获取用户权限
  async getUserAuthInfo() {
    await this.init();
    const authInfo = {
      // 是否查看权限
      hasViewAuth: this.hasViewAuth,
      // 是否登录
      isLogin: this.isLogin,
    };
    return authInfo;
  }
  // 处理在app中的登录
  async handleInApp() {
    // APP中
    if (config['client_env'] !== 'app') {
      return false;
    }
    // 环境可能会有不同
    const appBridge = appApi.createLuckyBridge();
    const authInfo = (await appBridge.getAuthorizationCode()) || {};
    // 没有取到授权 --> 即没登录
    if (authInfo?.code) {
      const isLoginSuccess = await this.checkAuthCode({
        code: authInfo.code,
      });
      return isLoginSuccess;
    } else {
      return false;
    }
  }
  // 处理在小程序中的登录
  async handleInMini() {
    // 小程序中
    if (!['mini', 'alipaymini'].includes(config['client_env'])) {
      return false;
    }
    const { authCode = '' } = this.urlParams;
    // 小程序授权码缓存key
    const MINI_AUTH_CODE = 'wap_mini_auth_code';
    const cacheAuthCode = localStorage?.getItem(MINI_AUTH_CODE) || '';
    // 当前链接携带的授权码 和 上一次的授权码 不一致
    if (authCode !== '' && authCode !== cacheAuthCode) {
      localStorage?.setItem(MINI_AUTH_CODE, authCode);
      const isLoginSuccess = await this.checkAuthCode({
        code: authCode,
      });
      return isLoginSuccess;
    } else {
      return false;
    }
  }

  // 接口授权
  async checkAuthCode(authInfo = {}) {
    try {
      const result = await AJAX.capi({
        url: '/resource/m/open/check',
        params: {
          loading: false,
          ...authInfo,
        },
      });
      // 环境可能会有不同
      const content = result?.content || {};
      if (content?.checked && content?.memberId) {
        config[LoginAuth.CONFIG_KEY.MOBILE] = content?.mobile;
        config[LoginAuth.CONFIG_KEY.MEMBER] = content?.memberId;
        config[LoginAuth.CONFIG_KEY.MEMBER_NO] = content?.memberNo;
        localStorage?.setItem(LoginAuth.CACHE_KEY.MOBILE, content?.mobile);
        // localStorage?.setItem(LoginAuth.CACHE_KEY.MEMBER, content?.memberId);
        localStorage?.setItem(LoginAuth.CACHE_KEY.MEMBER_NO, content?.memberNo);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // 接口
      return false;
    }
  }
}

export default new LoginAuth();
