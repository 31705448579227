import NewBie from './new-bie';
import Fission from './fission';
import RedPacket from './red-packet';
import HalfCoupon from './half-coupon';
import CouponGift from './coupon-gift';
import LuckinCard from './luckin-card';
import MedalRanking from './medal-ranking';
import TakeMealCode from './take-meal-code';
import TakeCargoCode from './take-cargo-code';
import InviteNewUser from './invite-new-user';
import InviteRegister from './invite-register';
import AdInviteRegister from './ad-invite-register';
import NewInviteRegister from './new-invite-register';
import InviteUserRegister from './invite-user-register';
import NewUserRegion from './new-user-region';
import Membership from './membership';
import MedalWall from './medal-wall';
import Harmony from './harmony';
import AutoRenewUpgrade from './auto-renew-upgrade';
import DiscountZone from './discount-zone';

export default {
  NewBie,
  Fission,
  RedPacket,
  HalfCoupon,
  CouponGift,
  LuckinCard,
  MedalRanking,
  TakeMealCode,
  TakeCargoCode,
  InviteNewUser,
  InviteRegister,
  AdInviteRegister,
  NewInviteRegister,
  InviteUserRegister,
  NewUserRegion,
  Membership,
  MedalWall,
  Harmony,
  AutoRenewUpgrade,
  DiscountZone,
};
