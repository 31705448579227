import React, { Suspense, useState, useEffect, useCallback, lazy } from 'react';
import ReactDom from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import { Router, Route, Switch } from 'react-router-dom';
// 导入配置文件
import config from '@spa/config';
import appApi from '@spa/utils/app-api';
import miniApi from '@spa/utils/mini-api';
import loginAuth from '@spa/utils/login-auth';
import LuckLoading from '@spa/components/LuckLoading';
import { browserHistory } from '@/spa/utils/history';
// 页面加载前处理
import '@spa/libs/before-load';
// 由于用到window.sysType,则要放在before-load后面
const NavigationBar = lazy(() => import(/* webpackChunkName: 'common/navigation-bar' */ '@spa/components/NavigationBar'));
import routes from './router';
// 引入基础样式
import '@spa/css/base.less';

// 需要兼容OPPO等机型浏览器的promise.prototype.finally，否则会报错导致页面白屏
promiseFinally.shim();

// app桥接
const luckyBridge = appApi.createLuckyBridge();

const Index = () => {
  // 是否显示web内容
  const [isShowWebContent, setIsShowWebContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenLogin, setIsOpenLogin] = useState(false);

  useEffect(() => {
    async function init() {
      // 验证登录
      try {
        const userAuthInfo = await loginAuth.getUserAuthInfo();
        if (!userAuthInfo?.hasViewAuth) {
          // 主动跳转
          if (config['client_env'] === 'app') {
            document.addEventListener('visibilitychange', handleLoginBack);
            luckyBridge?.goLoginPage();
          } else if (config['client_env'] === 'mini') {
            miniApi.openLogin();
          }
        }
        setIsShowWebContent(!!userAuthInfo?.hasViewAuth);
      } catch (error) {
        // 待埋点登录异常
        setIsShowWebContent(false);
      }
      setIsLoading(false);

      // 设置用户信息给sentry
      if (typeof window.LKSentry?.setSentryConfig === 'function') {
        window.LKSentry?.setSentryConfig(config);
      }
    }
    init();
  }, [handleLoginBack]);

  // 处理登录返回
  const handleLoginBack = useCallback(async () => {
    if (document.visibilityState === 'visible') {
      try {
        // 执行登录
        const isLoginSuccess = await loginAuth.handleInApp();
        setIsShowWebContent(!!isLoginSuccess);
      } catch (error) {
        // 待埋点登录异常
        setIsShowWebContent(false);
      }
      setIsOpenLogin(false);
      document.removeEventListener('visibilitychange', handleLoginBack);
    } else {
      // 如果隐藏了，代表打开了登录(进入页面里面切换应用程序概率小)
      setIsOpenLogin(true);
    }
  }, [setIsShowWebContent]);

  return (
    <>
      {(isShowWebContent && (
        <Suspense fallback={config['client_env'] === 'app' && !navigator?.userAgent?.includes('com.lucky.luckincoffee') ? <div /> : <LuckLoading />}>
          <Router history={browserHistory}>
            {/* 路由加载 */}
            <Switch>
              {[...routes].map(({ path, component, exact = true, sensitive = true, meta = {}, title }) => {
                const Component = lazy(component);
                // 判断页面是否在iframe中打开 是的话则恒定不添加自定义的导航栏
                const isInIframe = self.frameElement?.tagName == 'IFRAME';
                const needHandleNavBar =
                  !isInIframe && !meta?.notHandleNavBar && config['client_env'] === 'app' && !config['isHarmony'] && config['client_brand'] !== 'tea';
                return (
                  <Route
                    key={path}
                    exact={exact}
                    sensitive={sensitive}
                    path={path}
                    component={(props) => {
                      if (meta.pageTitle) {
                        document.title = meta.pageTitle;
                      }
                      return (
                        <>
                          {/* 默认都处理导航栏 */}
                          {(needHandleNavBar && (
                            <NavigationBar
                              {...props}
                              title={meta.pageTitle || ''}
                              Component={Component}
                              isDynamicTitle={meta?.isDynamicTitle}
                            ></NavigationBar>
                          )) || <Component {...props}></Component>}
                        </>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </Router>
        </Suspense>
      )) || (
        <>
          {!isLoading && !isOpenLogin && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%',
                fontSize: '0.32rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span>{'您还未登录'}</span>
                <span
                  style={{
                    padding: '0.1rem 0.8rem',
                    color: '#fff',
                    fontSize: '0.32rem',
                    backgroundColor: '#0022ab',
                    borderRadius: '0.46rem',
                    marginTop: '0.25rem',
                  }}
                  onClick={() => {
                    // 手动跳转
                    if (config['client_env'] === 'app') {
                      // 因为登录后app未主动刷新当前页，h5先简单做兼容
                      document.addEventListener('visibilitychange', handleLoginBack);
                      luckyBridge?.goLoginPage();
                    } else if (config['client_env'] === 'mini') {
                      // 执行重登录
                      miniApi?.openLogin();
                    } else {
                      window.location.href = 'https://m.lkcoffee.com/app/download';
                    }
                  }}
                >
                  {'去登录'}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

ReactDom.render(<Index key={'app-root'} />, global.document.querySelector('#app'));
