import CapsuleStation from './views/capsule-station';
import Catcher from './views/catcher';
import CmsOrdinary from './views/cms-ordinary';
import Dice from './views/dice';
import IceBox from './views/ice-box';
import Ordinary from './views/ordinary';
import Slot from './views/slot';
import Wawa from './views/wawa';
import IssusingCoup from './views/issusing-coup';
import BlessBag from './views/bless-bag';

import ordinaryConfig from './views/ordinary/splitConfig';
import mock from './views/cms-ordinary/mock';

export default {
  CapsuleStation,
  Catcher,
  CmsOrdinary,
  Dice,
  IceBox,
  Ordinary,
  Slot,
  Wawa,
  IssusingCoup,
  BlessBag,
  ordinaryConfig,
  mock,
};
