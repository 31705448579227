/**
 * @author linzl
 */
// 测试路由
import TestRoutes from './test';

// 官网路由
import WebsiteRoutes from './website';
import ActivityRoutes from './activity';
import WechatCommunityRoutes from './wechat-community';
import MiniRoutes from './mini';

// app路由
import AppRoutes from './app';

import UpdateMobile from '@coffee/pages/update-mobile';
const NotFound = () => import(/* webpackChunkName: 'common/not-found' */ '@spa/components/NotFound');
export default [
  // 公共组件（活动、小程序等有使用）
  {
    title: '修改手机号码',
    path: '/update/mobile',
    component: UpdateMobile,
  },
  {
    title: '页面未找到',
    path: '/404',
    component: NotFound,
    meta: {
      notHandleNavBar: false,
    },
  },
  ...AppRoutes,
  ...ActivityRoutes,
  ...TestRoutes,
  ...MiniRoutes,
  ...WechatCommunityRoutes,
  // 官网有匹配/， 所以必须放在最后
  ...WebsiteRoutes,
];
