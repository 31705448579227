import Home from './home';
import RightsDetail from './rights-detail';
import BeansDetail from './beans-detail';
import Rules from './rules';
import Qa from './qa';
import DrawByBeans from './draw-by-beans';
import DrawCommonBeans from './draw-common-beans';

export default {
  Home,
  RightsDetail,
  BeansDetail,
  Rules,
  Qa,
  DrawByBeans,
  DrawCommonBeans,
};
