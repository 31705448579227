import Enterprise from './enterprise';
import Member from './common-agreement';
import Payment from './payment';
import Privacy from './privacy';
import VipPay from './vip-pay';
import Sodexo from './sodexo';
import List from './list';
import ThirdParty from './third-party';
import VirtualProduct from './virtual-product';
import UnregisterInstructions from './unregister-instructions';
import SdkList from './sdk-list';
import LkcardAutoRenew from './lkcard-auto-renew';
import CommonAgreement from './common-agreement';
import SubContent from './sub-content';

export default {
  Enterprise,
  Member,
  Payment,
  Privacy,
  VipPay,
  Sodexo,
  List,
  ThirdParty,
  VirtualProduct,
  UnregisterInstructions,
  SdkList,
  LkcardAutoRenew,
  CommonAgreement,
  SubContent,
};
