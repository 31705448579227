import Home from './home';
import HomeV2 from './homeV2';
import PurchaseRecords from './purchase-records';
import AutoRenew from './auto-renew';
import PayResult from './pay-result';
import RenewList from './renew-list';

export default {
  Home,
  HomeV2,
  PurchaseRecords,
  AutoRenew,
  PayResult,
  RenewList,
};
