/**
 * @author linzl
 * @description frontend-backend-config 前端和后端的配置数据
 */

// http 请求相关配置
export const HTTP_METHOD_CONFIG = {
  post: Symbol('post'),
  get: Symbol('get'),
};

const HTTP_METHOD = {
  [HTTP_METHOD_CONFIG.post]: 'post',
  [HTTP_METHOD_CONFIG.get]: 'get',
};

// api 名称
export const APIS_NAME_CONFIG = {
  wx: Symbol('wx'),
  walletapi: Symbol('walletapi'),
  discountUnionApi: Symbol('discountUnionApi'),
  capi: Symbol('capi'),
  sapi: Symbol('sapi'),
  empapi: Symbol('empapi'),
  partnerapi: Symbol('partnerapi'),
  activityapi: Symbol('activityapi'),
  walletapi: Symbol('walletapi'),
  ecapi: Symbol('ecapi'),
};

const APIS_NAME = {
  [APIS_NAME_CONFIG.wx]: '/wx',
  [APIS_NAME_CONFIG.walletapi]: '/walletapi',
  [APIS_NAME_CONFIG.discountUnionApi]: '/discountUnionApi',
  [APIS_NAME_CONFIG.capi]: '/capi',
  [APIS_NAME_CONFIG.sapi]: '/sapi',
  [APIS_NAME_CONFIG.empapi]: '/empapi',
  [APIS_NAME_CONFIG.partnerapi]: '/partnerapi',
  [APIS_NAME_CONFIG.activityapi]: '/activityapi',
  [APIS_NAME_CONFIG.walletapi]: '/walletapi',
  [APIS_NAME_CONFIG.ecapi]: '/ecapi',
};

// 需要广告上报的接口 （egg配置优先级最高,pageConfig.needAdsReportApis）
const needAdsReportApis = [
  // 复工券
  '/resource/m/promo/activity/workcoupon',
  // 普通领券
  '/resource/m/promo/activity/send',
];

// 前端默认配置数据
const frontendConfig = {
  // 获取真实ip
  sohu: {
    cityUrl: 'https://pv.sohu.com/cityjson?ie=utf-8',
  },
  // 是否debug
  debug: process.env.NODE_LUCKY_ENV === 'local',
  // 是否生产环境
  isProd: process.env.NODE_LUCKY_ENV === 'prod',
  isHarmony: navigator?.userAgent?.toLowerCase?.().includes?.('com.lucky.luckincoffee'),
  ...HTTP_METHOD,
  ...APIS_NAME,
  needAdsReportApis,
  HTTP_METHOD_CONFIG,
};

// 查询地址栏参数
const getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substring(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};

// 处理环境和版本
function handleVersionEnv() {
  const appversion = getQueryString('appversion');
  const miniversion = getQueryString('miniversion');
  const cacheAppversion = localStorage.getItem('appversion') || 0;
  const cacheMiniversion = localStorage.getItem('miniversion') || 0;
  const cacheBrandType = localStorage.getItem('brandType') || '';
  const brandType = getQueryString('brandType') || cacheBrandType;
  const config = {};
  if (appversion > 0 || (cacheAppversion > 0 && !miniversion)) {
    config['appversion'] = appversion || cacheAppversion;
    config['client_env'] = 'app';
    let clientBrand = '';
    if (brandType && brandType !== 'null') {
      switch (brandType) {
        case 'LK001':
          clientBrand = 'coffee';
          break;
        default:
          clientBrand = 'tea';
          break;
      }
    }
    config['client_brand'] = clientBrand;

    localStorage.setItem('appversion', config['appversion']);
    localStorage.setItem('brandType', brandType);
  } else if (miniversion > 0 || cacheMiniversion > 0) {
    if (cacheMiniversion > 0) {
      // 从缓存取到环境信息，则需要判断是在微信中 是微信小程序还是微信浏览器(因为微信浏览器和微信小程序webview共用缓存)
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('micromessenger')) {
        if (window.__wxjs_environment === 'browser') {
          // 非小程序环境下逻辑
          config['client_env'] = 'browser';
          localStorage.setItem('miniversion', '');
        } else {
          config['miniversion'] = cacheMiniversion;
          config['client_env'] = 'mini';
        }
      } else {
        config['miniversion'] = cacheMiniversion;
        config['client_env'] = 'mini';
      }
    } else {
      config['miniversion'] = miniversion;
      config['client_env'] = 'mini';
      localStorage.setItem('miniversion', config['miniversion']);
    }
  } else {
    config['client_env'] = 'browser';
  }
  // 兼容有的小程序打开内嵌h5地址没有拼接miniversion(目前只判断微信小程序)
  const miniTag = getQueryString('frommini');
  if (miniTag === 'mini') {
    config['client_env'] = 'mini';
  }
  return config;
}

// 合并后端配置数据
function assign() {
  const backendConfig = window._cf_ || {};
  if (typeof backendConfig === 'string') {
    try {
      const eggConfig = JSON.parse(Buffer.from(backendConfig, 'base64'));
      const isOpenWebp = !!eggConfig['luckyfe.wap.webp']?.webp || false;
      if (window.isSupportWebp && isOpenWebp) {
        const isIos = !!window.sysType?.ios;
        const imgReg = isIos
          ? /(http)(s)?:\/\/(img|static|mktoss)(.*?)\.(png|jpg|jpeg|bmp)/gi
          : /(http)(s)?:\/\/(img|static|mktoss)(.*?)\.(png|jpg|gif|jpeg|bmp)/gi;
        const tempResult = JSON.stringify(eggConfig);
        const parseResult = tempResult.replace(imgReg, (imgUrl) => {
          if (/^(http)(s)?:\/\/(mktoss)\./.test(imgUrl)) {
            const ossSubfix = '?x-oss-process=image/format,webp';
            return `${imgUrl}${ossSubfix}`;
          } else {
            return `${imgUrl}_.webp`;
          }
        });
        const mergeConfig = Object.assign({}, frontendConfig, JSON.parse(parseResult), handleVersionEnv());
        return mergeConfig;
      } else {
        return Object.assign({}, frontendConfig, eggConfig, handleVersionEnv());
      }
    } catch (error) {
      console.warn('backendConfig json string parse object failed!');
      return Object.assign({}, frontendConfig, handleVersionEnv());
    }
  }
}

// 合并window中的配置
export default assign();
