/**
 * @author linzl
 */
import Test from '@coffee/pages/test';
export default [
  {
    title: '测试app 桥接',
    path: '/test/app-api',
    component: Test.AppApi,
  },
  {
    title: '测试app 桥接',
    path: '/test/tools',
    component: Test.Tools,
  },
];
