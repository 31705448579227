/**
 * @author linzl
 */
import WechatMini from '@coffee/pages/mini';

export default [
  {
    title: '幸运送',
    path: '/giftcard/mini',
    component: WechatMini.GiftCard,
  },
  {
    title: '微信卡包活动',
    path: '/mini/wechatCard',
    component: WechatMini.CardCoupon.Receive,
  },
  {
    title: '微信卡包优惠券',
    path: '/mini/wechatCoupon',
    component: WechatMini.CardCoupon.Description,
  },
];
