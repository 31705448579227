/**
 * @author linzl
 */
import WechatCommunity from '@coffee/pages/wechat-community';
export default [
  {
    title: '领券中心首页',
    path: '/wechatCommunity/couponCentre/home',
    component: WechatCommunity.CouponCentre.Home,
    exact: true,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '领券中心确认订单',
    path: '/wechatCommunity/couponCentre/order/confirm',
    component: WechatCommunity.CouponCentre.ConfirmOrder,
    exact: true,
    meta: {
      pageTitle: '确认订单',
      notHandleNavBar: true,
    },
  },
  {
    title: '领券中心支付结果',
    path: '/wechatCommunity/couponCentre/order/confirmResult',
    component: WechatCommunity.CouponCentre.ConfirmOrderResult,
    exact: true,
  },
  {
    title: '任务勋章',
    path: '/wechatCommunity/couponCentre/taskMedal',
    component: WechatCommunity.CouponCentre.TaskMedal,
    exact: false,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '老虎机抽券活动',
    path: '/wechatCommunity/slotCoupon',
    component: WechatCommunity.SlotCoupon,
  },
  {
    title: '骰子抽券活动',
    path: '/wechatCommunity/diceCoupon',
    component: WechatCommunity.DiceCoupon,
  },
  {
    title: '娃娃机抽券活动',
    path: '/wechatCommunity/wawaCoupon',
    component: WechatCommunity.WawaCoupon,
  },
  {
    title: 'app订阅直播',
    path: '/wechatCommunity/subscribeLive',
    component: WechatCommunity.SubscribeLive,
  },
  {
    title: '社群专享价确认订单',
    path: '/wechatCommunity/exclusivePrice/order/confirm',
    component: WechatCommunity.ExclusivePrice.ConfirmOrder,
    exact: true,
  },
  {
    title: '引导加入福利群',
    path: '/wechatCommunity/guideWelfare',
    component: WechatCommunity.GuideWelfare,
    exact: true,
  },
];
