/**
 * @author linzl
 */
import Website from '@coffee/pages/website';
export default [
  {
    title: '首页',
    path: '/',
    component: Website,
    exact: false,
    meta: {
      notHandleNavBar: true,
    },
  },
];
