import React from 'react';
import { Toast, Icon } from 'antd-mobile';
import LuckLoading from '@spa/components/LuckLoading';

const LuckToast = {
  loading: (duration = 0, onClose = null, mask = true) => {
     // 自定义icon
    const loading = <LuckLoading></LuckLoading>;
    Toast.info(loading, duration, onClose, mask);
  },
  hide: () => {
    Toast.hide();
  },
  success: (content, duration = 2, onClose = null, mask = true) => {
    let el;
    if (content) {
      el = (
        <span>
          <Icon type={'check'} size="xxs"></Icon>
          <div style={{marginTop: '8px'}}>{content}</div>
        </span>
      );
    } else {
      el = <Icon type={'check'} size="sm"/>;
    }

    return Toast.info(el, duration, onClose, mask);
  },
  fail: (content, duration = 2, onClose = null, mask = true) =>
    // 先包一层
    Toast.fail(content, duration, onClose, mask),

  info: (content, duration, onClose = null, mask = true) => {
    // 先包一层
    Toast.info(content, duration, onClose, mask)
  }
};

export default LuckToast;
