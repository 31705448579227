/**
 * @author linzl
 */
import Activities from '@coffee/pages/activities';
import config from '@spa/config';

// cms裂变配置
let splitConfig;
if (config.debug) {
  splitConfig = Activities.Fission.mock.splitConfig;
} else {
  splitConfig = config.splitConfig || {};
}
const cmsRoutes = [];
// 如果有裂变活动配置信息才作为路由
if (splitConfig.activityName) {
  const actObj = {
    path: `/fissioncms/:year/${splitConfig.activityName}/:id`,
    key: `/fissioncms/:year/${splitConfig.activityName}/:id`,
    title: splitConfig?.activityTitle || '',
    component: Activities.Fission.CmsOrdinary,
  };
  cmsRoutes.push(actObj);
}

// 以前常规裂变配置
const ordinaryRoutes = [];
Activities.Fission.ordinaryConfig.splitActivities.map((activity) => {
  const newActivity = Object.assign({}, Activities.Fission.ordinaryConfig.defaultActivity, activity);
  newActivity.activityRouters.map((activityRouter) => {
    const fission = {
      title: newActivity.title,
      path: activityRouter,
      // 组件懒加载
      component: Activities.Fission.Ordinary,
    };
    ordinaryRoutes.push(fission);
  });
});

export default [
  {
    title: '红包记录/接收红包',
    path: ['/redPacket/record', '/redPacket/receive'],
    component: Activities.RedPacket,
  },
  {
    title: '分享取货码',
    path: '/takeCargoCode/share',
    component: Activities.TakeCargoCode,
  },
  {
    title: '分享取餐码',
    path: '/takeMealCode/share',
    component: Activities.TakeMealCode,
  },
  {
    title: '会员成长',
    path: '/membership/home',
    component: Activities.Membership.Home,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '成长会员-权益详情',
    path: '/membership/rightsdetail',
    component: Activities.Membership.RightsDetail,
    // meta: {
    //   notHandleNavBar: true,
    // },
  },
  {
    title: '成长会员-小蓝豆明细',
    path: '/membership/beansdetail',
    component: Activities.Membership.BeansDetail,
    // meta: {
    //   notHandleNavBar: true,
    // },
  },
  {
    title: '成长会员-规则说明',
    path: '/membership/rules',
    component: Activities.Membership.Rules,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '成长会员-常见问题',
    path: '/membership/qa',
    component: Activities.Membership.Qa,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '成长会员-会员专属活动',
    path: '/membership/draw-by-beans',
    component: Activities.Membership.DrawByBeans,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '成长会员-会员专属活动',
    path: '/membership/draw-common-beans',
    component: Activities.Membership.DrawCommonBeans,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '鸿蒙-领取鸿蒙专项五折券',
    path: '/harmony/coupon',
    component: Activities.Harmony.HarmonyCoupon,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '鸿蒙-超级权益',
    path: '/harmony/superRights',
    component: Activities.Harmony.SuperRights,
  },
  {
    title: '鸿蒙-tab',
    path: '/harmony/tab',
    component: Activities.Harmony.HarmonyTab,
  },
  {
    title: '勋章墙',
    path: '/medalwall',
    component: Activities.MedalWall,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '门店消费排行榜',
    path: '/shop/medalranking',
    component: Activities.MedalRanking.Home,
    meta: {
      notHandleNavBar: true,
    },
  },
  // {
  //   title: '会员卡落地页',
  //   path: '/membercard/home',
  //   component: Activities.MemberCard.Home,
  //   meta: {
  //     notHandleNavBar: true,
  //   },
  // },
  // {
  //   title: '会员卡确认订单',
  //   path: '/membercard/order/confirm',
  //   component: Activities.MemberCard.ConfirmOrder,
  // },
  // {
  //   title: '会员卡支付结果',
  //   path: '/membercard/payresult',
  //   component: Activities.MemberCard.PayResult,
  // },
  {
    title: '新版瑞幸幸运会员',
    path: '/luckincard/home',
    component: Activities.LuckinCard.HomeV2,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '自由卡支付结果',
    path: '/luckincard/payresult',
    component: Activities.LuckinCard.PayResult,
    meta: {
      pageTitle: '支付结果',
    },
  },
  {
    title: '新版瑞幸幸运会员购买记录',
    path: '/luckincard/purchase/records',
    component: Activities.LuckinCard.PurchaseRecords,
    meta: {
      pageTitle: '购买记录',
    },
  },
  {
    title: '自动续费相关步骤',
    path: '/luckincard/auto/renew',
    component: Activities.LuckinCard.AutoRenew,
    meta: {
      pageTitle: '自动续费管理',
    },
  },
  {
    title: '自动续费管理列表',
    path: '/luckincard/renew/list',
    component: Activities.LuckinCard.RenewList,
    meta: {
      pageTitle: '自动续费管理',
    },
  },
  // 新人专区落地页
  {
    title: '新人专区首页',
    path: '/newuserregion/home',
    component: Activities.NewUserRegion.Home,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '免费专区',
    path: '/newbie/index',
    component: Activities.NewBie.Home,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '新人红包',
    path: '/newbie/redpacket',
    component: Activities.NewBie.RedPacket,
  },
  {
    title: '赠送指定优惠券',
    path: '/couponGift',
    component: Activities.CouponGift,
  },
  {
    title: '五折券类型领券页',
    path: '/coupon/:star',
    component: Activities.HalfCoupon,
  },
  {
    title: '大师、张震、汤唯独立拉新页面',
    path: ['/invite/:inviteId', '/invite/:inviteType/:inviteId'],
    component: Activities.InviteNewUser,
  },
  {
    title: '第一版拉新注册',
    path: '/invited/register',
    component: Activities.InviteUserRegister,
  },
  {
    title: '第二版拉新注册',
    path: '/invited/newRegister/:id',
    component: Activities.InviteUserRegister,
  },
  {
    title: '第三版拉新注册',
    path: '/invited/userRegister/:id',
    component: Activities.InviteUserRegister,
  },
  {
    title: 'cms 拉新配置',
    path: '/invitecms/:activityName/:id',
    component: Activities.InviteUserRegister,
  },
  {
    title: '广告拉新注册',
    path: '/inviteShare/:inviteType/:inviteId',
    component: Activities.AdInviteRegister,
  },
  {
    title: '扭蛋机',
    path: '/capsuleRollFission/:id',
    component: Activities.Fission.CapsuleStation,
  },
  {
    title: '娃娃机',
    path: '/wawaFission/:id',
    component: Activities.Fission.Catcher,
  },
  {
    title: '骰子',
    path: '/diceFission/:id',
    component: Activities.Fission.Dice,
  },
  {
    title: '冰箱',
    path: '/iceboxFission/:id',
    component: Activities.Fission.IceBox,
  },
  {
    title: '老虎机',
    path: '/slotFission/:id',
    component: Activities.Fission.Slot,
  },
  {
    title: '夹机占',
    path: '/lljStationFission/:id',
    component: Activities.Fission.Wawa,
  },
  {
    title: '发券机',
    path: '/issuingCoupFission/:id',
    component: Activities.Fission.IssusingCoup,
  },
  {
    title: '福袋',
    path: '/blessBagFission/:id',
    component: Activities.Fission.BlessBag,
  },
  {
    title: '折扣专区',
    path: '/discountZone/home',
    component: Activities.DiscountZone,
    meta: {
      notHandleNavBar: true,
    },
  },
  {
    title: '付费卡自动续费升级提示',
    path: '/autoRenewUpgrade',
    component: Activities.AutoRenewUpgrade,
    meta: {
      pageTitle: '瑞幸自由卡权益更新',
    },
  },
  // 裂变路由
  ...cmsRoutes,
  // 常规裂变路由
  ...ordinaryRoutes,
];
