import Agreement from './agreement';
import Mastercard from './mastercard';
import LiveVideo from './live-video';
import JoinUs from './join-us';
import InviteNewUser from './invite-new-user';
import EnterpriseAccount from './enterprise-account';
import Download from './download';
import HelpCentre from './help-centre';
import FocusWechat from './focus-wechat';
import QualifyNotification from './qualify-notification';
import InvitePeriodLimit from './invite-period-limit';
import InviteOfSchool from './invite-of-school';
import InviteSchoolSeason from './invite-school-season';
import InviteUnmannedExpress from './invite-unmanned-express';
import BlockChain from './blockchain';

export default {
  Mastercard,
  LiveVideo,
  JoinUs,
  InviteNewUser,
  EnterpriseAccount,
  Agreement,
  Download,
  HelpCentre,
  FocusWechat,
  QualifyNotification,
  InvitePeriodLimit,
  InviteOfSchool,
  InviteSchoolSeason,
  InviteUnmannedExpress,
  BlockChain,
};
